<template>
  <v-row class="py-0">
    <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Voucher Kasbon#</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.voucherKasbon }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Voucher Settlement#</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.voucherSettlement }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Kasbon#</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.kasbonNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Settlement#</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.settlementNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Old Kasbon#</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.oldKasbonNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="6">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Old Settlement#</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="6" lg="6">
              <span>{{ item.oldSettlementKasbon }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
    </v-col>
    <v-col cols="12" sm="12" md="1">
      <v-row class="py-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="printJournal">
                <v-icon>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
            <span>Journal</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2" v-if="!item.mergeJournal">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="toMergeJournal">
                <v-icon>
                  mdi-arrow-right-bold-hexagon-outline
                </v-icon>
              </v-btn>
            </template>
            <span>Merge Journal</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="restorePaymentJournal">
                <v-icon>
                  mdi-restore
                </v-icon>
              </v-btn>
            </template>
            <span>Restore Payment Journal</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2" v-if="item.mergeJournal">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="toGetJournal">
                <v-icon>
                  mdi-printer
                </v-icon>
              </v-btn>
            </template>
            <span>Preview Merge Journal</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2" v-if="item.mergeJournal">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="success" v-on="on" @click="addAdditional">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <span>Add Additional</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="warning" v-on="on" @click="deletePaymentJournal">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Delete Payment Journal</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="error" v-on="on" @click="deleteDialog">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            <span>Delete Merge</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row class="py-0 mb-2">
        <v-col cols="12" class="py-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" v-on="on" @click="back">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
            </template>
            <span>Back</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
    <dialog-delete
      :id="id"
      :dialog="dialog"
      :invoiceNumber="item.referenceBank"
      title="Join Payment Kasbon"
      @deleteItem="deleteItem"
      @close="close"
    ></dialog-delete>
  </v-row>
</template>

<script>
import DialogDelete from "@/components/DialogDelete";
export default {
  name: "detail-kasbon-combination",
  props: {
    id: Number,
  },
  components: {
    "dialog-delete": DialogDelete,
  },
  data() {
    return {
      item: {},
      dialog: false,
    };
  },
  methods: {
    async getKasbonCombination() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("combinationKasbon/getById", this.id)
        .then(response => {
          this.item = response.data;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async deletePaymentJournal() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("combinationKasbon/deletePaymentJournal", this.id)
        .then(() => {
          window.location.reload();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async restorePaymentJournal() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("combinationKasbon/restorePaymentJournal", this.id)
        .then(() => {
          window.location.reload();
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    toMergeJournal() {
      this.$router.push({ name: "Merge Journal Payment Kasbon", params: { id: this.id } });
    },
    toGetJournal() {
      this.$router.push({ name: "Detail Merge Journal", params: { id: this.id } });
    },
    addAdditional() {
      this.$router.push({ name: "Add Additional Payment", params: { id: this.id } });
    },
    back() {
      this.$router.go(-1);
    },
    printJournal() {
      this.$router.push({
        name: "Print Preview Journal Kasbon Combination",
        params: { id: this.id },
      });
    },
    async deleteItem(id) {
      await this.$store
        .dispatch("combinationKasbon/delete", id)
        .then(() => {
          this.dialog = false;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    deleteDialog() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {
    this.getKasbonCombination();
  },
};
</script>

<style></style>
